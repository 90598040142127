import React from 'react'
import { Link } from 'gatsby'
import ArticleInfo from '../ArticleInfo'

const Excerpt = ({ date, commentsLength, excerpt, slug }) => (
  <>
    <ArticleInfo date={date} commentsLength={commentsLength} /> -{' '}
    <span
      className="u-text--intro u-flow--inline"
      dangerouslySetInnerHTML={{
        __html: excerpt,
      }}
    />{' '}
    <Link className="u-link" to={slug}>
      Continua
    </Link>
  </>
)

export default Excerpt
