import React from 'react'
import { Link } from 'gatsby'
import cn from 'classnames'

import Excerpt from '../Excerpt'
import { graphql } from 'gatsby'

// Style
import style from './Article.module.scss'

const Box = ({
  title,
  date,
  commentsLength,
  excerpt,
  slug = '/',
  info,
  main,
}) => {
  return (
    <article className={style.root}>
      <header>
        <h4 className={cn(style.title, { h2: main })}>
          <Link to={slug}>{title}</Link>
        </h4>
      </header>
      <Excerpt
        date={date}
        commentsLength={commentsLength}
        excerpt={excerpt}
        slug={slug}
      />
    </article>
  )
}

export const boxFragment = graphql`
  fragment BoxFrontmatter on MarkdownRemark {
    id
    fields {
      slug
      description
    }
    frontmatter {
      date
      title
      templateKey
      published
      words {
        label
        value
      }
    }
  }
`

export default Box
