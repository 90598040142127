import React from 'react'
import { kebabCase, upperFirst } from 'lodash'
import { Link, graphql } from 'gatsby'
import cn from 'classnames'

// Components
import { Container, Row, Col } from 'reactstrap'
import { Box as ArticleBox } from '@components/Article'
import Section from '@components/Section'
import SEO from '@components/SEO'

const Parola = ({
  pageContext: { word, themes },
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const theme = themes.find((t) => t.words.includes(word))
  const otherWords = theme && theme.words ? theme.words : []
  return (
    <main>
      <SEO title={upperFirst(word)} />
      <Container>
        <Row className="mb-lg-5">
          <Col md="7" className="my-3 pb-5">
            <Row>
              <Col md="12">
                <header className="mt-5">
                  <p className="u-text--detail">Parola</p>
                  <h1 className="mb-4">{upperFirst(word)}</h1>
                </header>
                {edges.map((edge) => {
                  const { title, date } = edge.node.frontmatter
                  const { slug, description } = edge.node.fields
                  return (
                    <div className="mt-4" key={title}>
                      <ArticleBox
                        title={title}
                        date={date}
                        excerpt={description}
                        slug={slug}
                      />
                    </div>
                  )
                })}
              </Col>
            </Row>
          </Col>
          <Col md={{ size: 3, offset: 1 }} className="my-3 pb-5">
            <aside>
              <p className="u-text--detail mt-5">Appartiene al tema:</p>
              <h5>
                <Link to={`/tema/${kebabCase(theme.name)}`}>{theme.name}</Link>
              </h5>
              <div
                className="u-text--info"
                dangerouslySetInnerHTML={{
                  __html: theme.description,
                }}
              />
              <p className="u-text--detail mt-5">
                Le altre parole di questo tema sono:
              </p>
              <ul className="list-unstyled">
                {otherWords.map((oW) =>
                  oW === word ? null : (
                    <li key={oW}>
                      <Link to={`/parola/${kebabCase(oW)}`}>
                        {upperFirst(oW)}
                      </Link>
                    </li>
                  ),
                )}
              </ul>
            </aside>
          </Col>
        </Row>
      </Container>
    </main>
  )
}

export default Parola

export const tagPageQuery = graphql`
  query WordsQuery($word: String!) {
    allMarkdownRemark(
      filter: {
        frontmatter: {
          words: { elemMatch: { value: { eq: $word } } }
          published: { eq: true }
        }
      }
    ) {
      edges {
        node {
          ...BoxFrontmatter
        }
      }
    }
  }
`
